import React, { useState } from "react";
import CountryTable from "components/common/CountryTable";
import { useForm } from "react-hook-form";

// reactstrap components
import { Table, Input, FormGroup, Row, Col } from "reactstrap";
import useAlert from "hooks/useAlert";
import TextPopup from "components/common/TextModal";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import content from "../content";
import BranchTable from "components/common/BranchTable";

function Tables({ abilities, dispatchAbility }) {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const [branchTable, setBranchTable] = useState(false);
  const [countryTable, setCountryTable] = useState(false);
  const { alert, sweetAlert } = useAlert();

  // use form hook
  const { setValue } = useForm();

  const headers = [content.screen[culture], content.actions[culture]];
  const globalRoles = [
    {
      name: "View",
      arName: "عرض",
      role: "view",
    },
    {
      name: "Create",
      arName: "انشاء",
      role: "create",
    },
    {
      name: "Edit",
      arName: "تعديل",
      role: "edit",
    },
    {
      name: "Delete",
      arName: "حذف",
      role: "delete",
    },
  ];

  const screens = [
    {
      title: "Received Airshipment",
      arTitle: "الشحنات الجوية المستلمة",
      name: "AirCargo",
      roles: [
        ...globalRoles,
        {
          name: "Upload images",
          arName: "رفع صورة",
          role: "img",
        },
        {
          name: "Arrived",
          arName: "وصول الشحنة",
          role: "arraived",
        },
        {
          name: "update buk",
          arName: "تعديل تاريخ الوصول",
          role: "updatBulk",
        },
        {
          arName: "تغيير الشحنة الى بحرية",
          name: "Change Cargo To LCL",
          role: "changeToThisType",
        },
        {
          arName: "البحث المتقدم",
          name: "Advanced search",
          role: "recivedAdvanceFilter",
        },
        {
          arName: "البحث المتقدم في ال follow up",
          name: "Advanced search in follow up",
          role: "advanceFilter",
        },
      ],
    },
    {
      title: "Bulk Airshipment",
      arTitle: "تجميعات الشحنات الجوية",
      name: "AirCargoBulk",
      roles: [
        ...globalRoles,
        {
          name: "Link Orders",
          arName: "ربط الشحنات",
          role: "linkOrder",
        },
        {
          name: "Delete Orders",
          arName: "حذف الشحنات",
          role: "deleteOrders",
        },
      ],
    },
    {
      title: "Invoice Managment Air",
      arTitle: "ادارة الفواتير الجوية",
      name: "AirCargoInvoice",
      roles: [
        ...globalRoles,
        {
          name: "Swift",
          arName: "سويفت",
          role: "swift",
        },
        {
          name: "Approve",
          arName: "قبول",
          role: "approve",
        },
        {
          name: "Wrong",
          arName: "خاطئ",
          role: "wrong",
        },
        {
          name: "Saved",
          arName: "حفظ",
          role: "saved",
        },
        {
          name: "Contacted",
          arName: "تواصل",
          role: "contacted",
        },
        {
          arName: "تغيير الشحنة الى بحرية",
          name: "Change Cargo To LCL",
          role: "changeToThisType",
        },
        {
          arName: "البحث المتقدم",
          name: "Advanced search",
          role: "invoiceAdvanceFilter",
        },
      ],
    },
    // {
    //   title: "Shaheen Pay Air",
    //   name: "AirPrice",
    //   roles: [...globalRoles],
    // },
    {
      title: "Branches",
      arTitle: "الفروع",
      name: "Branch",
      roles: [...globalRoles],
    },
    {
      title: "Broker",
      arTitle: "البائعين",
      name: "Broker",
      roles: [...globalRoles],
    },
    {
      title: "Country",
      arTitle: "البلدان",
      name: "Country",
      roles: [
        ...globalRoles,
        {
          name: "Activate",
          arName: "تفعيل",
          role: "active",
        },
        {
          name: "Deactivate",
          arName: "الغاء تفعيل",
          role: "deactive",
        },
      ],
    },
    {
      title: "Customers settings",
      name: "Customer",
      arTitle: "الزبائن",
      roles: [...globalRoles],
    },
    {
      title: "Received LCL",
      arTitle: "الشحن البحري",
      name: "LCL",
      roles: [
        ...globalRoles,
        {
          name: "Upload images",
          arName: "رفع صورة",
          role: "img",
        },
        {
          name: "Arrived",
          arName: "وصول الشحنة",
          role: "arraived",
        },
        {
          name: "update buk",
          arName: "تعديل تاريخ الوصول",
          role: "updatBulk",
        },
        {
          arName: "تغيير الشحنة الى جوية",
          name: "Change Cargo To Air",
          role: "changeToThisType",
        },
        {
          arName: "البحث المتقدم",
          name: "Advanced search",
          role: "recivedAdvanceFilter",
        },
        {
          arName: "البحث المتقدم في ال follow up",
          name: "Advanced search in follow up",
          role: "advanceFilter",
        },
      ],
    },
    {
      title: "Received FCL",
      arTitle: "شحن الحاوية الكاملة",
      name: "FCL",
      roles: [
        ...globalRoles,
        {
          name: "Upload images",
          arName: "رفع صورة",
          role: "img",
        },
        {
          name: "Arrived",
          arName: "وصول الشحنة",
          role: "arraived",
        },
        {
          name: "update buk",
          arName: "تعديل تاريخ الوصول",
          role: "updatBulk",
        },
        {
          name: "Loaded",
          arName: "تم التحميل",
          role: "loaded",
        },
        {
          name: "Shipped",
          arName: "تم شحنها",
          role: "shipped",
        },
        {
          arName: "البحث المتقدم",
          name: "Advanced search",
          role: "invoiceAdvanceFilter",
        },
      ],
    },
    {
      title: "LCL Bulk",
      arTitle: "تجميعات الشحن البحري",
      name: "LCLBulk",
      roles: [
        ...globalRoles,
        {
          name: "Link Orders",
          arName: "ربط الشحنات",
          role: "linkOrder",
        },
        {
          name: "Delete Orders",
          arName: "حذف شحنات",
          role: "deleteOrders",
        },
      ],
    },
    {
      title: "Invoice Managment LCL",
      arTitle: "فواتير الشحن البحري",
      name: "LCLCargoInvoice",
      roles: [
        ...globalRoles,
        {
          name: "Swift",
          arName: "سويفت",
          role: "swift",
        },
        {
          name: "Approve",
          arName: "قبول",
          role: "approve",
        },
        {
          name: "Wrong",
          arName: "خاطئ",
          role: "wrong",
        },
        {
          name: "Saved",
          arName: "حفظ",
          role: "saved",
        },
        {
          name: "Contacted",
          arName: "تواصل",
          role: "contacted",
        },
        {
          arName: "تغيير الشحنة الى جوية",
          name: "Change Cargo To Air",
          role: "changeToThisType",
        },
        {
          arName: "البحث المتقدم",
          name: "Advanced search",
          role: "invoiceAdvanceFilter",
        },
      ],
    },
    {
      title: "Invoice Managment FCL",
      arTitle: "فواتير شحن الحاوية الكاملة",
      name: "FCLInvoice",
      roles: [
        ...globalRoles,
        {
          name: "Swift",
          arName: "سويفت",
          role: "swift",
        },
        {
          name: "Approve",
          arName: "قبول",
          role: "approve",
        },
        {
          name: "Wrong",
          arName: "خاطئ",
          role: "wrong",
        },
        {
          name: "Saved",
          arName: "حفظ",
          role: "saved",
        },
        {
          name: "Contacted",
          arName: "تواصل",
          role: "contacted",
        },
        {
          arName: "البحث المتقدم",
          name: "Advanced search",
          role: "invoiceAdvanceFilter",
        },
      ],
    },
    {
      title: "SP Air",
      arTitle: "SP الجوي",
      name: "SPAir",
      roles: [
        ...globalRoles,
        {
          name: "Payment info",
          arName: "معلومات الدفع",
          role: "amount",
        },
        {
          name: "Update state",
          arName: "تحديث الحالة",
          role: "update",
        },
        {
          name: "Cancel",
          arName: "الغاء",
          role: "cancel",
        },
      ],
    },
    {
      title: "SP LCL",
      arTitle: "SP LCL",
      name: "SPLcl",
      roles: [
        ...globalRoles,
        {
          name: "Payment info",
          arName: "معلومات الدفع",
          role: "amount",
        },
        {
          name: "Update state",
          arName: "تحديث الحالة",
          role: "update",
        },
        {
          name: "Cancel",
          arName: "الغاء",
          role: "cancel",
        },
      ],
    },
    {
      title: "SP FCL",
      arTitle: "SP FCL",
      name: "SPFcl",
      roles: [
        ...globalRoles,
        {
          name: "Payment info",
          arName: "معلومات الدفع",
          role: "amount",
        },
        {
          name: "Update state",
          arName: "تحديث الحالة",
          role: "update",
        },
        {
          name: "Cancel",
          arName: "الغاء",
          role: "cancel",
        },
      ],
    },
    {
      title: "User",
      arTitle: "المستخدمين",
      name: "User",
      roles: [
        ...globalRoles,
        {
          name: "Activate users",
          arName: "تفعيل المستخدمين",
          role: "useractive",
        },
        {
          name: "Deactivate users",
          arName: "تعطيل المستخدمين",
          role: "userdeactive",
        },
        {
          name: "Add role",
          arName: "اضافة صلاحية",
          role: "addRole",
        },
        {
          name: "Generate password",
          arName: "تعيين كلمة مرور",
          role: "generateurUsers",
        },
        {
          name: "Reset password",
          arName: "إعادة تعيين كلمة المرور",
          role: "restPassword",
        },
        {
          name: "Delete Not active users",
          arName: "حذف المستخدم غير النشط",
          role: "notacitve",
        },
      ],
    },
    {
      title: "Air Price",
      arTitle: "الاحتساب الجوي",
      name: "AirPrice",
      roles: [
        ...globalRoles,
        {
          title: "calculate",
          arName: "حساب",
          role: "calculate",
        },
      ],
    },
    {
      title: "LCL Price",
      arTitle: "احتساب LCL",
      name: "LCLPrice",
      roles: [
        ...globalRoles,
        {
          title: "calculate",
          arName: "حساب",
          role: "calculate",
        },
      ],
    },
    {
      title: "Transfer Price",
      arTitle: "سعر التحويلات",
      name: "TransferPrice",
      roles: [...globalRoles],
    },
    {
      title: "Packages in bulks",
      arTitle: "البضائع في التجميعات",
      name: "PkgsBulks",
      roles: [
        ...globalRoles,
        {
          name: "Paid",
          arName: "الدفع",
          role: "paid",
        },
        {
          name: "Deposite",
          arName: "إيداع",
          role: "createCustomerPaymentFromCargo",
        },
      ],
    },
    {
      title: "Ticket",
      arTitle: "التذاكر",
      name: "Ticket",
      roles: [
        ...globalRoles,
        {
          name: "Filter",
          arName: "بحث",
          role: "filter",
        },
        {
          name: "Assign holder",
          arName: "تعيين مالك",
          role: "assignHolder",
        },
        {
          name: "Reopen",
          arName: "اعادة فتح",
          role: "reOpen",
        },
        {
          name: "Resolve",
          arName: "تم الحل",
          role: "resolve",
        },
        {
          name: "close",
          arName: "إغلاق",
          role: "close",
        },
        {
          name: "Pending",
          arName: "قيد الإنتظار",
          role: "pending",
        },
      ],
    },
    {
      title: "Comments",
      arTitle: "التعليقات",
      name: "Comment",
      roles: [
        ...globalRoles,
        {
          name: "Update internal comment",
          arName: "تعديل تعليق داخلي",
          role: "externalComment",
        },
        {
          name: "Update external comment",
          arName: "تعديل تعليق خارجي",
          role: "internalComment",
        },
      ],
    },
    {
      title: "News",
      arTitle: "أخر الأخبار",
      name: "News",
      roles: [
        ...globalRoles,
        {
          name: "Status",
          arName: "تعطيل",
          role: "status",
        },
      ],
    },
    {
      title: "Warehouses",
      arTitle: "المخازن",
      name: "Warehouse",
      roles: [...globalRoles],
    },
    {
      title: "SpFeeSetting",
      arTitle: "اعدادات الحوالات",
      name: "SpFeeSetting",
      roles: [
        {
          name: "View",
          arName: "عرض",
          role: "view",
        },
        {
          name: "Create",
          arName: "انشاء",
          role: "create",
        },
      ],
    },
  ];

  const handleClick = (screen, value) => {
    dispatchAbility({ type: "ADD_ABILITY", screen, value });
  };

  const hasAbility = (screen, value) =>
    abilities.findIndex(
      (item) => item.claimName === screen && item.claimValue === value
    ) > -1;

  const handleClickRow = (index) => {
    const screen = screens[index];

    if (isAllRowChecked(index)) {
      return dispatchAbility({
        type: "UNCHECK_ALL_ROW",
        screen: screen.name,
      });
    }
    dispatchAbility({
      type: "CHECK_ALL_ROW",
      screen: screen.name,
      roles: screen.roles,
    });
  };

  const isAllRowChecked = (index) => {
    let rolesNo = 0;
    abilities.forEach((item) => {
      if (item.claimName === screens[index].name) rolesNo++;
    });

    return rolesNo >= screens[index].roles.length;
  };

  const selectCountry = (country) => {
    dispatchAbility({
      type: "ADD_ABILITY",
      screen: "OperationCountry",
      value: country,
    });
  };

  const selectBranch = (branch) => {
    dispatchAbility({
      type: "ADD_ABILITY",
      screen: "OperationBranch",
      value: branch,
    });
  };

  const isCountryExist = (countryName) => {
    const index = abilities.findIndex(
      (item) => item.claimValue === countryName
    );
    if (index > -1) return true;
    return false;
  };

  const isBranchExist = (branchName) => {
    const index = abilities.findIndex((item) => item.claimValue === branchName);
    if (index > -1) return true;
    return false;
  };

  const noOfCountries = () => {
    const num = abilities.filter(
      (item) => item.claimName === "OperationCountry"
    )?.length;
    if (num >= 1) return `${num} ${content.selected[culture]}`;
    // if (num === 1) return `only ${num} country selected`;
    return false;
  };

  const noOfBranches = () => {
    const num = abilities.filter(
      (item) => item.claimName === "OperationBranch"
    )?.length;
    if (num >= 1) return `${num} ${content.selected[culture]}`;
    // if (num === 1) return `only ${num} ${content.selected[culture]}`;
    return false;
  };

  const countries = abilities
    .filter((item) => item.claimName === "OperationCountry")
    .map((item) => item.claimValue);

  const branches = abilities
    .filter((item) => item.claimName === "OperationBranch")
    .map((item) => item.claimValue);

  return (
    <>
      {alert}
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            {headers.map((item) => (
              <th scope="col" key={item}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="table-row">
            <th scope="row">
              <span className="mb-0 text-sm custom-table-cell">
                {content.operationCountry[culture]}
              </span>
            </th>
            <th>
              <span style={{ position: "relative", top: "15px" }}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Input
                        id="country"
                        placeholder={`-- ${content.selectCountry[culture]} --`}
                        type="text"
                        onChange={() => {}}
                        value={noOfCountries() || ""}
                        onClick={() => setCountryTable(true)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </span>
            </th>
          </tr>
          <tr className="table-row">
            <th scope="row">
              <span className="mb-0 text-sm custom-table-cell">
                {content.operationBranch[culture]}
              </span>
            </th>
            <th>
              <span style={{ position: "relative", top: "15px" }}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Input
                        id="country"
                        placeholder={`-- ${content.selectBranch[culture]} --`}
                        type="text"
                        onChange={() => {}}
                        value={noOfBranches() || ""}
                        onClick={() => setBranchTable(true)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </span>
            </th>
          </tr>
          {screens.map((screen, index) => (
            <tr className={`table-row`} key={`${screen}-${index}`}>
              <th scope="row">
                <span style={{ position: "relative", top: "-15px" }}>
                  <FormGroup
                    className="form-check"
                    style={{ marginInlineEnd: "1.25rem" }}
                  >
                    <Input
                      type="checkbox"
                      className="cursor-pointer"
                      checked={isAllRowChecked(index)}
                      onChange={() => handleClickRow(index)}
                    />
                  </FormGroup>
                  <span
                    className="mb-0 text-sm custom-table-cell"
                    style={{ marginInlineStart: "1.25rem" }}
                  >
                    {culture === "en" ? screen.title : screen.arTitle}
                  </span>
                </span>
              </th>
              <td scope="row">
                <div className="d-flex justify-content-start">
                  {screen.roles?.map((role, index) => (
                    <FormGroup
                      key={`${role.role}-${index}`}
                      className="form-check"
                      style={{
                        marginInlineEnd: "1.25rem",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <Input
                        id={`${screen.name}-${role.name}-${index}`}
                        type="checkbox"
                        className="cursor-pointer"
                        checked={hasAbility(screen.name, role.role)}
                        onChange={() => handleClick(screen.name, role.role)}
                      />
                      <label
                        className="form-control-label cursor-pointer ms-1"
                        htmlFor={`${screen.name}-${role.name}-${index}`}
                      >
                        {culture === "en" ? role.name : role.arName}
                      </label>
                    </FormGroup>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={() => setCountryTable(false)}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <CountryTable
          // setCountry={setCountry}
          setCountryTable={setCountryTable}
          setValue={setValue}
          sweetAlert={sweetAlert}
          multiple
          selectCountry={selectCountry}
          isCountryExist={isCountryExist}
          countries={countries}
        />
      </TextPopup>
      <TextPopup
        modal={branchTable}
        text="Next"
        handleCancel={() => setBranchTable(false)}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <BranchTable
          // setCountry={setCountry}
          setModal={setBranchTable}
          setValue={setValue}
          sweetAlert={sweetAlert}
          multiple
          selectBranch={selectBranch}
          isBranchExist={isBranchExist}
          branches={branches}
        />
      </TextPopup>
    </>
  );
}

export default Tables;
